import React from "react";
import { useTranslation } from "react-i18next";

import GlassGanjaSelection from "../img/glass_ganja_selectoion.png";
import InsideOld1 from "../img/inside_old_1.png";
import InsideOld from "../img/inside_old.png";
import NiceDisplayOfWeed from "../img/nice_desplay_of_weed.png";
import NiceGanjaDisplay from "../img/Nice_ganja_display.png";
import NorthernLightsGummiesBangkok from "../img/Northern_lights_gummies_bangkok.png";
import OGKushGummie from "../img/OGKUSH_gummie.png";
import OutsideDusk from "../img/outside_dusk.png";
import SelectionOfWeed from "../img/selection_of_weed.png";
import CharlotteAngelGummie from "../img/Charlotte_Angel_Gummie.png";
import ChopakaShopBangkok from "../img/Chopaka_shop_Bangkok.png";
import CannabisNearMeBangkok from "../img/Cannabis_near_me_bangkok.png";
import interior from "../img/interior.png";
import interior2 from "../img/interior2.png";
import interior3 from "../img/interior3.png";


const Gallery = () => {
    const { t } = useTranslation();
  
    const images = [
      interior2,

      GlassGanjaSelection,
      InsideOld1,
      InsideOld,
      NiceDisplayOfWeed,
      NiceGanjaDisplay,
      NorthernLightsGummiesBangkok,
      OGKushGummie,
      interior,
      SelectionOfWeed,
      CharlotteAngelGummie,
    ];
  

    return (
        <div className="relative isolate px-6 py-16 sm:py-24 lg:px-8">
          <div className="mx-auto max-w-7xl">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl mb-12 text-center">
              {t("gallery.sectionTitle")}
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {images.map((src, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center w-full h-[300px] bg-gray-100 rounded-lg shadow-lg overflow-hidden"
                >
                  <img
                    className="w-full h-full object-cover"
                    src={src}
                    alt={t(`gallery.images.${index}`, `Gallery Image ${index + 1}`)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      );
};

export default Gallery;